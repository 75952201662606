import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Masonry from "react-masonry-css"

function Case({ slug, featuredImage, cc, title, subtitle }) {
  return (
    <div key={slug} className="col-span-6 ">
      <div className="imageScale mb-4 rounded-xl">
        <Link to={`/work/${slug}`}>
          <GatsbyImage
            image={featuredImage.childImageSharp.gatsbyImageData}
            className=""
          />
        </Link>
      </div>
      <h3 className="font-medium mt-2">{title}</h3>
      <div className="text-sm font-regular mb-8 md:mb-2">{subtitle}</div>
    </div>
  )
}

function Casenotready({ slug, featuredImage, cc, title, subtitle }) {
  return (
    <div
      key={slug}
      className={`${cc} cursor-not-allowed hover:saturate-0 transition-all filter`}
    >
      <div className="mb-4">
        <GatsbyImage
          image={featuredImage.childImageSharp.gatsbyImageData}
          className="rounded-md md:rounded-lg"
        />
      </div>
      <div className="inline-flex items-base mb-.5">
        <h3 className="font-medium">{title}</h3>{" "}
        <div className="ml-2 rounded-md bg-realGray-800 font-medium px-1.5 py-1 text-xs inline-block">
          W.I.P
        </div>
      </div>
      <div className="text-sm font-regular mb-8 md:mb-2">{subtitle}</div>
    </div>
  )
}
export default function Caseswork() {
  const data = useStaticQuery(graphql`
    query WorkCases {
      desktopImage: allMdx(
        sort: { order: ASC, fields: frontmatter___date }
        filter: {
          frontmatter: { type: { eq: "work" }, availablecc: { eq: true } }
        }
      ) {
        nodes {
          frontmatter {
            cc
            slug
            availablecc
            title
            subtitle
            featuredImageDesktop {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      mobileImage: allMdx(
        sort: { order: ASC, fields: frontmatter___date }
        filter: {
          frontmatter: { type: { eq: "work" }, availablecc: { eq: false } }
        }
      ) {
        nodes {
          frontmatter {
            cc
            slug
            availablecc
            title
            subtitle
            featuredImageMobile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  `)
const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  500: 1
};
  return (
    <div className="mt-28 mb-32">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid "
          columnClassName="my-masonry-grid_column"
        >
          {data.desktopImage.nodes.map(
            ({
              frontmatter: {
                featuredImageDesktop,
                availablecc,
                slug,
                title,
                subtitle,
                cc,
              },
            }) =>
              availablecc ? (
                <Case
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageDesktop}
                  slug={slug}
                  title={title}
                />
              ) : (
                <Casenotready
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageDesktop}
                  slug={slug}
                  title={title}
                />
              )
          )}
        </Masonry>

                
    </div>
  )
}
