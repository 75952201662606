import React from "react"
import { Helmet } from "react-helmet"
import Caseswork from "../components/caseswork"

export default function Work() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Services"></meta>

        <title>Lance / Work</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="blackTheme no-scrollbar" />
      </Helmet>
      <>
      <Caseswork />
      
      </>
    </>
  )
}
